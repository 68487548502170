import * as Sentry from '@sentry/astro'

Sentry.init({
  dsn: 'https://2169a06df0e2c1a5da5d665107a83b58@o15755.ingest.us.sentry.io/4508370193481728',

  // enableTracing: false,
  // tracesSampleRate: 0,
  // autoSessionTracking: false,
  // replaysSessionSampleRate: 0,
  // replaysOnErrorSampleRate: 100,

  sendClientReports: false,
})
